import React, { useState } from 'react'
import { IdAndName } from '@src/interfaces'
import {
  BottomSheet,
  Button,
  Header,
  InputGroup,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import RadioSelectInput, {
  createNewKey,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { bulkEditRequisitionJobPostings } from '@src/api/jobPosting'
import NewMultiSelect from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import { arrayErrorsToFormError } from '@src/utils/form'
import uniq from 'lodash/uniq'

export type BulkUpdateRequisitionJobField =
  | 'team'
  | 'specialisation'
  | 'recruiter'
  | 'seniority'
  | 'locations'

interface BulkUpdateJobPopupProps {
  open: boolean
  onSuccess: () => void
  onClose: () => void
  field: BulkUpdateRequisitionJobField
  selectedItems?: number[]
  onCreateNew?: (onChangeAction: (entity: IdAndName) => void) => void
}

const getTitleFromEntity = (entity: BulkUpdateRequisitionJobField) => {
  switch (entity) {
    case 'team':
      return 'Change team'
    case 'locations':
      return 'Change locations'
    case 'recruiter':
      return 'Change recruiter'
    case 'seniority':
      return 'Change seniority'
    case 'specialisation':
      return 'Change role'
    default:
      return ''
  }
}

const getLabelFromEntity = (entity: BulkUpdateRequisitionJobField) => {
  switch (entity) {
    case 'team':
      return 'Team'
    case 'locations':
      return 'Location'
    case 'recruiter':
      return 'Recruiter'
    case 'seniority':
      return 'Seniority'
    case 'specialisation':
      return 'Role'
    default:
      return ''
  }
}

const getSelectorFromEntity = (entity: BulkUpdateRequisitionJobField) => {
  switch (entity) {
    case 'team':
      return selectorKeys.team
    case 'locations':
      return selectorKeys.location
    case 'recruiter':
      return selectorKeys.employee
    case 'seniority':
      return selectorKeys.seniority
    case 'specialisation':
      return selectorKeys.specialisations
    default:
      return null
  }
}

export const BulkUpdateJobPopup = ({
  open,
  onSuccess,
  onClose,
  field,
  selectedItems,
  onCreateNew,
}: BulkUpdateJobPopupProps) => {
  const [pending, setPending] = useState(false)
  const [value, setValue] = useState<IdAndName | null>(null)
  const [seniorityMin, setSeniorityMin] = useState<IdAndName | null>(null)
  const [seniorityMax, setSeniorityMax] = useState<IdAndName | null>(null)
  const [locations, setLocations] = useState<IdAndName[]>([])

  const statusPopup = useStatusPopup()
  const selector = getSelectorFromEntity(field)

  const showCreateNewButton = field === 'team' || field === 'specialisation'

  const onSubmit = (newEntity?: IdAndName) => {
    if (!selectedItems) {
      return
    }

    const valueToSubmit = newEntity || value

    if (newEntity) {
      setValue(newEntity)
    }

    setPending(true)

    let payload

    if (field === 'seniority' && seniorityMin && seniorityMax) {
      payload = {
        seniority_min: seniorityMin,
        seniority_max: seniorityMax,
      }
    } else if (field === 'locations') {
      payload = {
        locations,
      }
    } else if (valueToSubmit) {
      payload = { [field]: valueToSubmit }
    }

    if (!payload) {
      return
    }

    bulkEditRequisitionJobPostings(selectedItems, payload)
      .then(() => {
        setPending(false)
        setValue(null)
        onSuccess()
      })
      .catch(error => {
        const errors = arrayErrorsToFormError(error?.response?.data)
        const apiErrors = uniq(
          Object.values(errors)
            .map(err => (typeof err === 'string' ? err : undefined))
            .filter(Boolean),
        )

        setPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to update</StatusPopup.Title>
            <StatusPopup.Description>
              {apiErrors?.length
                ? apiErrors.join(', ')
                : getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  const renderContent = () => {
    switch (field) {
      case 'seniority':
        return (
          <InputGroup variant="horizontal">
            <RadioSelectInput
              label="Min Seniority"
              selector={selectorKeys.seniority}
              value={seniorityMin}
              onChange={setSeniorityMin}
            />
            <RadioSelectInput
              label="Max Seniority"
              selector={selectorKeys.seniority}
              value={seniorityMax}
              onChange={setSeniorityMax}
            />
          </InputGroup>
        )

      case 'locations':
        return (
          <NewMultiSelect<IdAndName>
            selector={selectorKeys.location}
            onChange={selectedOptions => {
              setLocations(selectedOptions.map(option => option.value))
            }}
            value={locations.map(optionValue => ({
              value: optionValue,
              label: optionValue.name,
            }))}
          />
        )

      default:
        return (
          <RadioSelectInput
            label={getLabelFromEntity(field)}
            selector={selector}
            value={value}
            onChange={option => {
              if (typeof option?.id === 'string' && option.id === createNewKey) {
                onCreateNew?.(newEntity => onSubmit(newEntity))
              } else {
                setValue(option)
              }
            }}
            showCreateNewButton={showCreateNewButton}
            allowSetValueToCurrentOption={showCreateNewButton}
          />
        )
    }
  }

  let disabled = !value

  if (field === 'seniority') {
    disabled = !seniorityMin || !seniorityMax
  } else if (field === 'locations') {
    disabled = !locations.length
  }

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>{getTitleFromEntity(field)}</Header.Title>
      </Header>

      {renderContent()}

      <BottomSheet.Actions>
        <Button onClick={() => onSubmit()} pending={pending} disabled={disabled} elevated>
          Update
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
