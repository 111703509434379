import uniq from 'lodash/uniq'

import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import {
  createCustomFieldColumn,
  employeesAccessGroupColumn,
  employeesAccessLevelColumn,
  employeesCountryColumn,
  employeesDepartmentColumn,
  employeesEmailColumn,
  employeesEntityColumn,
  employeesFirstNameColumn,
  employeesFunctionalManagerColumn,
  employeesJobTitleColumn,
  employeesLastNameColumn,
  employeesLineManagerColumn,
  employeesLocationColumn,
  employeesMiddleNameColumn,
  employeesPreferredNameColumn,
  employeesRoleColumn,
  employeesSeniorityColumn,
  employeesStartDateColumn,
  employeesTeamColumn,
} from '@src/constants/columns/employeesV2'
import { CustomFieldsForSectionInterface } from '@src/interfaces/customFields'
import { EditableTableRenderMode } from '@src/features/GenericEditableTable/components'
import {
  baseBulkEditFieldsDependencies,
  baseFieldsForBulkEdit,
  CreateCallback,
} from '../common'
import { supportedEmployeeCustomFieldsTypes } from '@src/features/EditableEmployeesTable/common/constants'

export const row =
  (
    onCreate: CreateCallback,
    mode: EditableTableRenderMode,
    customFields: CustomFieldsForSectionInterface[] = [],
  ) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<EmployeesSimpleInterface>> => ({
    cells: [
      {
        ...employeesEmailColumn(options.onChange),
        width: 200,
      },
      {
        ...employeesFirstNameColumn(options.onChange),
        width: 120,
      },
      {
        ...employeesMiddleNameColumn(options.onChange),
        width: 200,
      },
      {
        ...employeesLastNameColumn(options.onChange),
        width: 120,
      },
      {
        ...employeesPreferredNameColumn(options.onChange),
        width: 120,
      },
      {
        ...employeesTeamColumn(
          options.onChange,
          onChangeAction => onCreate('team', onChangeAction),
          mode,
        ),
        width: 150,
      },
      {
        ...employeesRoleColumn(
          options.onChange,
          onChangeAction => onCreate('role', onChangeAction),
          mode,
        ),
        width: 150,
      },
      {
        ...employeesSeniorityColumn(options.onChange, mode),
        width: 150,
      },
      {
        ...employeesLineManagerColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesFunctionalManagerColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesDepartmentColumn(
          options.onChange,
          onChangeAction => onCreate('department', onChangeAction),
          mode,
        ),
        width: 200,
      },
      {
        ...employeesEntityColumn(
          options.onChange,
          onChangeAction => onCreate('companyEntity', onChangeAction),
          mode,
        ),
        width: 200,
      },
      {
        ...employeesLocationColumn(
          options.onChange,
          onChangeAction => onCreate('location', onChangeAction),
          mode,
        ),
        width: 200,
      },
      {
        ...employeesCountryColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesJobTitleColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesStartDateColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...(mode === 'bulkFlow'
          ? employeesAccessLevelColumn(options.onChange)
          : employeesAccessGroupColumn(options.onChange)),
        width: 200,
      },
      ...customFields.map(cf => {
        const isSupported = supportedEmployeeCustomFieldsTypes.includes(cf.input_type.id)

        if (!isSupported) {
          return null
        }
        const customFieldColumn = createCustomFieldColumn(cf)
        return {
          ...customFieldColumn(options.onChange),
          width: 200,
        }
      }),
    ].filter(Boolean),
  })

export const mandatoryBulkSessionFields = ['email', 'first_name', 'last_name']
export const optionalBulkSessionFields = uniq([
  ...baseFieldsForBulkEdit,
  'line_manager',
  'location',
  'access_level',
  'middle_name',
  'preferred_name',
  'functional_manager',
  'department',
  'entity',
  'country',
  'job_title',
  'start_date',
])

export const bulkFieldsDependencies = {
  ...baseBulkEditFieldsDependencies,
  location: ['location'],
  line_manager: ['line_manager'],
}
