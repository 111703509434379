import {
  DetailsCell,
  Icon,
  Token,
  Text,
  HStack,
  TabBar,
  Box,
  Flex,
  Spinner,
} from '@revolut/ui-kit'
import React, { useEffect } from 'react'
import { TargetsList } from '../Widgets/Targets/TargetsList'
import { TargetsCharts } from './TargetsCharts'
import { GoalKpiDetails, GoalPreviewInterface } from '@src/interfaces/goals'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useLocalStorage } from '@src/hooks/useLocalStorage'

export const TargetsPreviewWidget = ({
  onSelected,
  loading = false,
  onTableSelected,
}: {
  onSelected: (target: GoalKpiDetails) => void
  loading?: boolean
  onTableSelected: (tab: 'chart' | 'table') => void
}) => {
  const [tab, setTab] = useLocalStorage<'chart' | 'table'>(
    'preferable_goal_target_preview_mode',
    'chart',
  )
  const { values } = useLapeContext<GoalPreviewInterface>()

  const targets = values.kpis

  useEffect(() => {
    onTableSelected(tab)
  }, [tab])

  if (!targets.length && !loading) {
    return null
  }

  return (
    <DetailsCell>
      <DetailsCell.Title>
        <HStack space="s-8">
          <Icon name="TurboTransfer" color={Token.color.greyTone20} />
          <Text variant="h6" color={Token.color.greyTone50}>
            Metrics
          </Text>
        </HStack>
      </DetailsCell.Title>
      <DetailsCell.Content>
        <TabBar
          variant="segmented"
          value={tab}
          onChange={selected => selected && setTab(selected)}
        >
          <TabBar.Item to="chart">
            <Text lineHeight="secondary" use="div" mx="s-8">
              <Icon name="BarChart" size={14} />
            </Text>
          </TabBar.Item>
          <TabBar.Item to="table">
            <Text lineHeight="h2" use="div" mx="s-8">
              <Icon name="16/ListBullet" size={14} />
            </Text>
          </TabBar.Item>
        </TabBar>
      </DetailsCell.Content>
      <DetailsCell.Note>
        {loading ? (
          <Flex width="100%" minHeight={538} justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        ) : tab === 'table' ? (
          <Box mt="s-16">
            <TargetsList onSelected={onSelected} viewMode />
          </Box>
        ) : (
          <TargetsCharts targets={targets} onEdit={onSelected} />
        )}
      </DetailsCell.Note>
    </DetailsCell>
  )
}
