import React, { useEffect, useState } from 'react'
import HierarchyTree from '@components/Hierarchy/HierarchyTree'
import styled from 'styled-components'
import { HierarchyNodeInterface } from '@src/interfaces/hierarchy'
import { newTabTo, pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  getFunctionalHierarchy,
  getHierarchyEmployeePath,
  getWrongReportingEmployee,
} from '@src/api/functions'
import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import Loader from '@components/CommonSC/Loader'
import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import {
  employeeNameColumn,
  employeeNameIdStatusColumn,
  functionalManagerColumn,
  lineManagerColumn,
} from '@src/constants/columns/employee'
import { roleNameColumn } from '@src/constants/columns/role'
import { seniorityNameColumn } from '@src/constants/columns/seniority'
import { teamNameColumn } from '@src/constants/columns/team'
import { locationNameColumn } from '@src/constants/columns/location'
import { navigateTo } from '@src/actions/RouterActions'
import { EmployeeInterface } from '@src/interfaces/employees'
import { FunctionInterface, TalentStatsInterface } from '@src/interfaces/functions'
import { Colored } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { useTheme } from '@src/styles/theme'
import WrongFunctionLegend from '@components/Hierarchy/WrongFunctionLegend'
import { PermissionTypes } from '@src/store/auth/types'
import { colorGetter } from '@src/styles/colors'
import {
  Absolute,
  Box,
  Checkbox,
  CheckboxGroup,
  Color,
  Flex,
  Switch,
  Text,
} from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'

interface HierarchyProps {
  stats?: TalentStatsInterface
  data: FunctionInterface
}

const HierarchyContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
`

const WrongReportingSwitch = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto;
  color: ${colorGetter(Color.GREY_TONE_50)};
  grid-gap: 4px;
  position: absolute;
  top: 32px;
  right: 0;
`

const EmployeeSelector = styled.div`
  display: grid;
  grid-template-columns: 320px auto;
  grid-gap: 10px;
  align-items: center;
  position: absolute;
  top: 32px;
`

const EmployeeRows: RowInterface<EmployeeInterface> = {
  cells: [
    {
      ...employeeNameColumn,
      width: 350,
    },
    {
      ...roleNameColumn,
      width: 130,
    },
    {
      ...seniorityNameColumn,
      width: 100,
    },
    {
      ...teamNameColumn,
      width: 200,
    },
    {
      ...lineManagerColumn,
      width: 150,
    },
    {
      ...functionalManagerColumn,
      width: 150,
    },
    {
      ...locationNameColumn,
      width: 200,
    },
    {
      ...employeeNameIdStatusColumn,
      width: 160,
    },
  ],
}

enum Filters {
  PerformanceGrades,
  InternalOnly,
  OnlyFromOtherFunctions,
  IncludeRequisitions,
}

interface HierarchyFilterInterface {
  only_from_function?: boolean
  include_requisitions?: boolean
  employee_type?: 'internal'
}

const Hiearchy = ({ stats, data }: HierarchyProps) => {
  const theme = useTheme()
  const { query, changeQueryParam, deleteQueryParam } = useQuery()
  const [checkBoxValue, setCheckBoxValue] = useState<Filters[]>([
    Filters.IncludeRequisitions,
  ])
  const [filters, setFilters] = useState<HierarchyFilterInterface>({
    include_requisitions: true,
  })
  const [loading, setLoading] = useState(false)
  const [showTags, setShowTags] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState<OptionInterface>()
  const [rootData, setRootData] = useState<HierarchyNodeInterface>()
  const [showPerformanceSwitch, setShowPerformanceSwitch] = useState(false)
  const companyTable = useTable<EmployeeInterface>({
    getItems: () => getWrongReportingEmployee(data.id),
  })

  useEffect(() => {
    fetchRootData()
  }, [
    query.showTable,
    filters.only_from_function,
    filters.employee_type,
    filters.include_requisitions,
  ])

  const fetchRootData = async () => {
    try {
      setLoading(true)
      const functionHeadId = data?.owner?.id
      if (functionHeadId) {
        const functionalHeadData = await getFunctionalHierarchy(
          data.id,
          functionHeadId,
          filters,
        )
        if (
          functionalHeadData?.data?.field_options?.permissions?.includes?.(
            PermissionTypes.ViewReportsPerformanceEmployees,
          )
        ) {
          setShowPerformanceSwitch(true)
        }
        setRootData(functionalHeadData?.data)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSelectEmployee = async (employee: OptionInterface) => {
    if (data && employee) {
      setCheckBoxValue([])
      setSelectedEmployee(employee)
      try {
        setLoading(true)
        const result = await getHierarchyEmployeePath(data.id, employee.id)
        if (result.data?.employees?.length) {
          const [lastRow, ...restRows] = result.data.employees.reverse()
          const employees = restRows.reduce((prev, curr) => {
            const indexOfSubordinate = curr.reports!.findIndex(val => val.id === prev.id)
            curr.isBranch = true
            if (curr.reports?.[indexOfSubordinate]) {
              curr.reports[indexOfSubordinate].isBranch = true
              curr.reports[indexOfSubordinate].reports = prev.reports
              if (curr.reports[indexOfSubordinate].id === lastRow.id) {
                curr.reports[indexOfSubordinate].reports = undefined
                curr.reports[indexOfSubordinate].zoomIn = true
              }
            }
            return curr
          }, lastRow)

          setRootData(employees)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  const onChildrenRequest = async (node: HierarchyNodeInterface) => {
    const result = await getFunctionalHierarchy(data.id, node.id, filters)

    if (result.data.reports) {
      return result.data.reports
    }

    return []
  }

  const handleTitleClick = (node: HierarchyNodeInterface) => {
    if (node.is_requisition) {
      newTabTo(ROUTES.FORMS.REQUISITION.ROLE, { id: node.id })
    } else {
      newTabTo(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: node.id })
    }
  }

  const handleFilterChange = (filter: Filters[]) => {
    setCheckBoxValue(filter)
    const hierarchyFilters: HierarchyFilterInterface = {}
    if (!showTags && filter.includes(Filters.PerformanceGrades)) {
      setShowTags(true)
    } else if (showTags && !filter.includes(Filters.PerformanceGrades)) {
      setShowTags(false)
    }

    if (filter.includes(Filters.InternalOnly)) {
      hierarchyFilters.employee_type = 'internal'
    }

    if (filter.includes(Filters.OnlyFromOtherFunctions)) {
      hierarchyFilters.only_from_function = true
    }

    hierarchyFilters.include_requisitions = filter.includes(Filters.IncludeRequisitions)

    setFilters(hierarchyFilters)
  }

  const handleRowEdit = (employee: EmployeeInterface) => {
    navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: employee.id }))
  }

  return (
    <HierarchyContainer>
      {rootData && !query.showTable && !loading && (
        <HierarchyTree
          onNodeTitleClick={handleTitleClick}
          showTags={showTags}
          additionalLegend={<WrongFunctionLegend />}
          onChildrenRequest={onChildrenRequest}
          rootNodeData={rootData}
        />
      )}
      {!query.showTable && (
        <EmployeeSelector>
          <RadioSelectInput
            value={selectedEmployee}
            label="Find Employee"
            selector={selectorKeys.employee}
            onChange={employee => {
              if (!employee) {
                setSelectedEmployee(undefined)
                fetchRootData()
                return
              }
              handleSelectEmployee(employee)
            }}
            clearable
            size="compact"
          />
          {loading && <Loader size="medium" />}
        </EmployeeSelector>
      )}
      {query.showTable && (
        <Flex style={{ position: 'relative' }} flex="1 0" mt="64px">
          <AdjustableTable<EmployeeInterface>
            name={TableNames.FunctionHierarchy}
            useWindowScroll
            dataType="Employee"
            row={EmployeeRows}
            {...companyTable}
            onRowClick={handleRowEdit}
            noDataMessage="No data to show"
          />
        </Flex>
      )}
      {stats?.missing_employee_count ? (
        <WrongReportingSwitch>
          <Switch
            checked={!!query.showTable}
            onChange={() => {
              if (query.showTable) {
                deleteQueryParam('showTable')
              } else {
                changeQueryParam('showTable', 'true')
              }
            }}
          >
            <Text>Show employees with wrong reporting line</Text>
          </Switch>
          {` · `}
          <Colored color={theme.colors.warning}>{stats.missing_employee_count}</Colored>
        </WrongReportingSwitch>
      ) : null}
      {!query.showTable && (
        <Absolute top="94px">
          <CheckboxGroup value={checkBoxValue} onChange={handleFilterChange}>
            {group => (
              <>
                {showPerformanceSwitch && (
                  <></>
                  // TEMPORAIRLY REMOVE GRADES VISIVILITY UNTIL BE FIX
                  // <Box mb="s-8">
                  //   <Checkbox
                  //     {...group.getInputProps({ value: Filters.PerformanceGrades })}
                  //   >
                  //     <Text>View performance grades</Text>
                  //   </Checkbox>
                  // </Box>
                )}
                <Box mb="s-8">
                  <Checkbox {...group.getInputProps({ value: Filters.InternalOnly })}>
                    <Text>Internal employees only</Text>
                  </Checkbox>
                </Box>
                <Box mb="s-8">
                  <Checkbox
                    {...group.getInputProps({ value: Filters.OnlyFromOtherFunctions })}
                  >
                    <Text>Only people from the function</Text>
                  </Checkbox>
                </Box>
                <Box mb="s-8">
                  <Checkbox
                    {...group.getInputProps({
                      value: Filters.IncludeRequisitions,
                    })}
                  >
                    <Text whiteSpace="nowrap" use="div">
                      Show requisitions
                    </Text>
                  </Checkbox>
                </Box>
              </>
            )}
          </CheckboxGroup>
        </Absolute>
      )}
    </HierarchyContainer>
  )
}

export default Hiearchy
