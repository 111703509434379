import React from 'react'
import { Group } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

import { PageWrapper } from '@src/components/Page/Page'
import { OnboardingChecklistHeader } from '../../OnboardingChecklistV2/components/OnboardingChecklistHeader'
import { pathToUrl } from '@src/utils/router'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { PageBody } from '@src/components/Page/PageBody'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { GoalsSettingsProps } from './Goals'
import { PageHeader } from '@src/components/Page/Header/PageHeader'

const Title = () => (
  <FormattedMessage id="goalsSettings.page.title" defaultMessage="Goals settings" />
)

const Description = () => (
  <FormattedMessage
    id="goalsSettings.page.description"
    defaultMessage="Setting for all goals in the system"
  />
)

export const GoalsSettingsAll = ({
  backUrl,
  targetTypesRoute,
  approvalProcessRoute,
  roadmapsRoute,
  isOnboarding,
}: GoalsSettingsProps) => {
  return (
    <PageWrapper>
      {isOnboarding ? (
        <OnboardingChecklistHeader
          title={<Title />}
          description={<Description />}
          backUrl={pathToUrl(backUrl)}
        />
      ) : (
        <PageHeader
          title={<Title />}
          subtitle={<Description />}
          backUrl={pathToUrl(backUrl)}
        />
      )}

      <PageBody>
        <Group>
          <NewSelectionCard
            icon="Switches"
            title="Target types"
            to={targetTypesRoute}
            use={InternalLink}
          />
          <NewSelectionCard
            icon="CheckVerification"
            title="Approval processes"
            subtitle="Create and manage custom approval processes for your business"
            to={approvalProcessRoute}
            use={InternalLink}
          />
          <NewSelectionCard
            icon="DataLimit"
            title="Roadmaps"
            use={InternalLink}
            to={roadmapsRoute}
          />
        </Group>
      </PageBody>
    </PageWrapper>
  )
}
